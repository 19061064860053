<template>
    <div id="pageList" class="GoodsTypeList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">货物类型名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入货物类型名称进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_NAME" label="货物类型名称"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import GoodsTypeCard from './GoodsTypeCard';
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "GoodsTypeList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                pageList: {
                    queryParam: {
                       name:"" 
                    },
                    modelComp: GoodsTypeCard,
                    modelMethod: "/goodsType/pageData"
               }
            })
            onMounted(()=>{
            })
            //---------------------------computed------------

            return{
                ...toRefs(dataObj)
            }
        }
    });
</script>

<style scoped>
    .GoodsTypeList{
        width: 100%;
    }
</style>